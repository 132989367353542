<template>
  <div class="container">
    <div class="section">

        <div class="section-heading">
          <h3 class="title is-2">MAKES</h3>
          <h4 class="subtitle is-5">Higedaruma Make List</h4>
        </div>

        <div class="row columns is-multiline">
          <div v-for='[key, value] in makes' :key='key' class="column is-4">
            <div class="card large">
              <div class="card-image">
                <figure class="image is-16by9">
                  <a v-bind:href='`https://protopedia.net/prototype/${value.id}`'>
                    <img v-bind:src="value.image1" alt="Image">
                  </a>
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4 no-padding"> {{ value.prototypeNm }} </p>
                  </div>
                </div>
                <div class="content">
                  {{ value.summary }}
                  <div class="background-icon"><span class="icon-twitter"></span></div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      makes: null
    }
  },
  mounted () {
    axios
      .get('https://protopedia.net/api/prototypes.json?userNm=higedaruma')
      .then(response => {
          this.makes = new Map(response.data.map(o => [o.id, o]))
      })
      .catch(error => {
        console.log(error)
      })
  }  
}
</script>


<style scoped lang="scss">
.portfolio-container .card {
  margin-bottom: 2em;
}

.section-heading {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 6em;
}

.image img {object-fit: cover; }
</style>